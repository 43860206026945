import CleverTap from "clevertap-web-sdk/clevertap";
import { getBrowserInfo } from "./browserInfo";
import { UserInfoType } from "./requests/types/auth";

type ArgumentTypes<F extends (...args: any[]) => any> = F extends (...args: infer A) => any ? A : never;

let clevertapanalytics: CleverTap | null = null;

type screenProperty = Partial<{
  category: string;
  name: string;
  url: string;
  path: string;
  title: string;
  referring_domain: string;
  referrer: string;
  origin: "web";
}>;

type learnScreenProperty = screenProperty & { learn_category: string };
type faqScreenProperty = screenProperty & { faq_category: string };
type loginScreenProperty = screenProperty & { referralProgram: boolean };

export const useClevertapAnalytics = () => {
  const initializeClevertap = () => {
    return new Promise(async (resolve) => {
      let firstTimeInit = false;

      if (!process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID) {
        console.warn(
          "something wrong with NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID",
          process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID
        );
        resolve(null);
        return;
      }
      if (!clevertapanalytics && typeof window !== "undefined") {
        const clevertapModule = await import("clevertap-web-sdk");
        clevertapanalytics = clevertapModule.default;
        firstTimeInit = true;
      }

      if (clevertapanalytics && firstTimeInit) {
        clevertapanalytics.init(
          process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID,
          process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_REGION
        );
        clevertapanalytics.spa = false;
        clevertapanalytics.privacy.push({ optOut: false });
        clevertapanalytics.privacy.push({ useIP: false });
        clevertapanalytics.setLogLevel(3);
      }
      resolve(null);
    });
  };

  async function event(...args: ArgumentTypes<CleverTap["event"]["push"]>) {
    await initializeClevertap();
    clevertapanalytics?.event.push(...args);
  }

  async function screen(
    event: string,
    properties?: screenProperty | learnScreenProperty | faqScreenProperty | loginScreenProperty
  ) {
    const eventName = event;
    const document = window.document;
    const location = window.location;
    const navigator = window.navigator;
    const browserInfo = getBrowserInfo(navigator.userAgent);

    const eventProps = {
      name: properties?.name ?? event,
      category: properties?.category ?? "Public",
      url: properties?.url ?? location.href,
      path: properties?.path ?? location.pathname,
      title: properties?.title ?? document.title,
      referrer: properties?.referrer ?? document.referrer ?? "",
      referring_domain: properties?.referring_domain ?? document.referrer ? new URL(document.referrer).hostname : "",
      browser_name: browserInfo.name,
      browser_version: browserInfo.version
    };

    await initializeClevertap();

    clevertapanalytics?.event.push(eventName, eventProps);
  }

  async function clear() {
    await initializeClevertap();

    clevertapanalytics?.clear();
  }

  async function logout() {
    await initializeClevertap();

    clevertapanalytics?.logout();
    clevertapanalytics?.clear();
  }

  async function profile(data: UserInfoType) {
    await initializeClevertap();
    clevertapanalytics?.onUserLogin.push({
      Site: {
        Identity: data.contactId,
        Name: `${data.firstName} ${data.lastName}`,
        ...data
      }
    });
  }

  return { screen, event, profile, clear, logout };
};
