import { setAuthCookie } from "@helpers/cookies/auth";

export function setLocalStorageUserSession(sessionId: string) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("iCashUserSessionID", sessionId);
  }
}

export function removeLocalStorageUserSession() {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("iCashUserSessionID");
  }
}

export function isLoggedIn() {
  let sessionId: string | null = null;

  if (typeof localStorage !== "undefined") {
    sessionId = localStorage.getItem("iCashUserSessionID");
  }

  if (sessionId) return true;

  removeLocalStorageLogout();
  setAuthCookie(false);

  return false;
}

export function getUserSessionId() {
  let sessionId: string | null = null;

  if (typeof localStorage !== "undefined") {
    sessionId = localStorage.getItem("iCashUserSessionID");
    return sessionId;
  }

  return null;
}

export function removeLocalStorageLogout() {
  removeLocalStorageUserSession();
}
