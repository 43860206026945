import { UserInfoType } from "./requests/types/auth";

let firstTimeInit = false;

export const useSingularAnalytics = () => {
  const initialize = () => {
    return new Promise(async (resolve, reject) => {
      const sdkKey = process.env.NEXT_PUBLIC_SINGULAR_SDK_KEY;
      const sdkSecret = process.env.NEXT_PUBLIC_SINGULAR_SDK_SECRET;
      const productId = process.env.NEXT_PUBLIC_SINGULAR_PRODUCT_ID;
      const isDisabled = process.env.NEXT_PUBLIC_SINGULAR_IS_DISABLED;

      if (!sdkKey || !sdkSecret || !productId || isDisabled) {
        resolve(null);
        return;
      }

      if (!firstTimeInit && typeof window !== "undefined") {
        const singular = require("singular-sdk");
        const config = new singular.SingularConfig(sdkKey, sdkSecret, productId);

        window.singularSdk.init(config);
        console.log("SingularDeviceId:", window.singularSdk.getSingularDeviceId());
        firstTimeInit = true;
      }

      resolve(null);
    });
  };

  const revenue = async (
    eventName: string,
    currency: "CAD",
    amount: number,
    properties?: Record<string, string | number | undefined | boolean>
  ) => {
    await initialize();

    if (window.singularSdk) {
      window.singularSdk.revenue(eventName, currency, amount, properties);
    }
  };

  const page = async (
    categoryName: string,
    pathName?: string,
    properties?: Record<string, string | boolean | number>
  ) => {
    await initialize();
    if (window.singularSdk) {
      window.singularSdk.pageVisit(categoryName);
    }
  };

  const reset = async () => {
    await initialize();
    window.singularSdk.logout();
  };

  const identify = async (userData: UserInfoType | undefined, callback?: () => void) => {
    await initialize();
    if (window.singularSdk && userData?.contactId) {
      await window.singularSdk.login(userData?.contactId?.toString());
      callback?.();
    }
  };

  const track = async (
    event: string,
    properties?: Record<string, string | boolean | number | undefined>,
    options?: Record<string, string | boolean | number>
  ) => {
    await initialize();
    if (window.singularSdk) {
      window.singularSdk.event(event, properties);
    }
  };

  return { track, page, reset, identify, revenue };
};
