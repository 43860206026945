export function getBrowserInfo(userAgent: string): { name: string; version: string } {
  let browserName = "Unknown";
  let browserVersion = "Unknown";

  if (userAgent.includes("Chrome")) {
    browserName = "Google Chrome";
    const match = userAgent.match(/Chrome\/(\S+)/);
    browserVersion = match?.[1] ?? "Unknown";
  } else if (userAgent.includes("Safari")) {
    browserName = "Apple Safari";
    const match = userAgent.match(/Version\/(\S+)/);
    browserVersion = match?.[1] ?? "Unknown";
  } else if (userAgent.includes("Firefox")) {
    browserName = "Mozilla Firefox";
    const match = userAgent.match(/Firefox\/(\S+)/);
    browserVersion = match?.[1] ?? "Unknown";
  } else if (userAgent.includes("Edge")) {
    if (userAgent.includes("Edg/")) {
      browserName = "Microsoft Edge (Chromium)";
      const match = userAgent.match(/Edg\/(\S+)/);
      browserVersion = match?.[1] ?? "Unknown";
    } else {
      browserName = "Microsoft Edge (Legacy)";
      const match = userAgent.match(/Edge\/(\S+)/);
      browserVersion = match?.[1] ?? "Unknown";
    }
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    browserName = "Opera";
    const match = userAgent.match(/(?:Opera|OPR)\/(\S+)/);
    browserVersion = match?.[1] ?? "Unknown";
  }

  return {
    name: browserName,
    version: browserVersion
  };
}
